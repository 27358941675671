<template>
  <div class="about-us-page login-page">
    <section class="au-first">
      <div class="container">
        <div class="au-texts">
          <div class="row">
            <div class="col-lg-6 col-md-12">
              <div class="about-us">
                <div class="sub" v-if="signIn!==''" v-html="signIn.description">
                 
                </div>
                
              </div>
            </div>
            <div class="col-lg-4 col-md-12">
              <div class="my-news-cards">
                <div class="news-cards">
                  <div class="n-title">{{ $t("Kirish") }}</div>
                  <div class="n-sub">
                    <span>{{ $t("Ro’yhatdan o’tmagan bo’lsangiz") }} </span
                    ><span
                      ><router-link
                        :to="'/' + $i18n.locale + '/signUp'"
                        class="to-reg"
                      >
                        {{ $t("ro’yhatdan o’tish") }}
                      </router-link></span
                    ><span></span>
                  </div>
                  <form @submit.prevent="login()" class="log-form">
                    <div class="f-login">
                      <input
                        required
                        v-model="formdata.login"
                        type="text"
                        :placeholder="$t('Login')"
                      />
                    </div>
                    <div class="f-login">
                      <input
                        required
                        v-model="formdata.password"
                        type="password"
                        :placeholder="$t('Password')"
                        value=""
                      />
                    </div>
                    <p class="error-message">{{ error }}</p>
                    <input
                      v-if="!loginLoading"
                      type="submit"
                      :value="$t('Kirish')"
                      class="more-button"
                    />
                    <loader v-else></loader>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import axios from "axios";
import { mapState } from 'vuex';
export default {
  data() {
    return {
      formdata: {
        login: "",
        password: "",
      },
      error: "",
      errorPassword: "",
      loginLoading: false,
    };
  },
  computed : {
    ...mapState(['signIn', 'goEatSingle'])
  },
  methods: {
    login() {
      this.error = "";
      this.loginLoading = true;
      axios
        .post("user/login", this.formdata)
        .then((res) => {
          this.loginLoading = false;
          this.$store.commit("USER_DATA", res.data.data);
          if(this.goEatSingle) this.$router.go(-1)
          else this.$router.push('/')
        })
        .catch((error) => {
          this.loginLoading = false;
          this.error = error.response.data.message;
        });
    },
  },
  mounted(){
    this.$store.dispatch('signIn')
  },
  destroyed(){
     this.$store.commit('GO_EAT_SINGLE', false)
  }
};
</script>

<style>
.about-us-page.login-page
  .au-first
  .au-texts
  .news-cards
  .log-form
  .f-login.incorrect:after {
  content: "";
}
.error-message {
  font-family: Baloo2;
  font-size: 14px;
  color: #f11;
  width: 100%;
}
</style>